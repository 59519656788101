div.globe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    width: 180px;
    height: 180px;
    top: -32px;
    z-index: 999 !important;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px #606876;
    border-radius: 50%;
    margin-bottom: 20px;
}
