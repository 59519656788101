:root {
    --primary-color: #006BB3;
}

.form-check-input:checked[type=radio] {
    background: #374151;
}


.form-check-input:checked {
    background-color: #374151 !important;
    border-color: #c9d0d3 !important;
    border-style: solid;
    border-width: 4px;
}

.form-check-input[type=radio] {
    border-radius: 50%;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}


.radio-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #111827;
}

.submit-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    background: var(--primary-color);
    border-radius: 6px;
    flex: none;
    color: #FFFFFF;
    border: none;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 20px;
}

small {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: 'salt' on;
    color: #6B7280;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    margin-top: 12px !important;
}

a {
    cursor: pointer;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #0E212C;
    width: auto;
    text-decoration: none;
    border-bottom: 7px solid #E5E7EB;
}

/* to style the border underneath the links */
a.active {
    border-bottom: 7px solid var(--primary-color);
}


label {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    display: flex;
    align-items: center;
    font-feature-settings: 'salt' on;

    color: #0E212C;
    margin-top: 20px !important;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

textarea, input {
    background-color: #ffffff;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 2px solid #6B7280;
    width: auto;
    padding: 12px -10px 12px -10px;
    transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
    box-sizing: border-box;
}


select {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 2px solid #6B7280;
    color: #555555;
    display: inline-block;
    height: 46px;
    font-weight: 400;
    font-size: 14px;
    min-width: 100px;
}

.full-width-a {
    width: 100%;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.small-width {
    width: 25%;
}

.no-outline:focus {
    outline: none;
}

nav {
    background: #F3F4F6;
    height: 60px;
    z-index: 1 !important;
}

.clearfix {
    overflow: auto;
}

.top-section {
    margin-top: 110px;
}

.w-220 {
    width: 220px !important;
    min-width: 220px !important;
}

.w-135 {
    width: 135px !important;
    min-width: 135px !important;
}

.w-auto {
    width: auto !important;
}

.clearfix::after {
    content: "\a";
    white-space: pre;
    clear: both;
    display: block;
}


.checkout-text {
    color: var(--primary-color);
    flex: none;
    order: 1;
    margin: 0px 7px;
}

.amount-due {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0E212C;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}

.amount {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    font-feature-settings: 'salt' on;
    color: #111827;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 2px;
}

.amount-due-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 600px;
    /*height: 65px;*/
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(96, 104, 118, 0.5);
    margin: 5px 0px;
}

.amount-due-no-order {
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0E212C;
    flex: none;
    flex-grow: 0;
    margin: 0px 0px;
}

.amount-due-big-font {
    font-weight: 800;
    font-size: 20px;
}

.amount-big-font {
    font-weight: 600;
    font-size: 24px;
}

.amount-no-order {

    line-height: 24px;
    display: flex;
    align-items: center;
    font-feature-settings: 'salt' on;
    color: #111827;
    flex: none;
    flex-grow: 0;
    margin: 0px 2px 20px 2px;
}

.billing-information-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 40px 40px;
    width: 600px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(96, 104, 118, 0.5);
    flex-grow: 0;
    margin: 40px 0px;
}

.m-b-20 {
    margin-bottom: 20px;
}

@media only screen and (max-width: 912px) {
    .top-section {
        margin-top: 200px;
    }
}

@media only screen and (max-width: 768px) {

    .full-width-a {
        width: 100%;
    }

    .full-width {
        width: 100%;
    }

    .small-width {
        width: 100%;
    }

    .amount-due {
        font-weight: 800;
        font-size: 18px;
        line-height: 28px;
        /* identical to box height, or 140% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #0E212C;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .amount {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        /* identical to box height, or 100% */

        display: flex;
        align-items: center;
        font-feature-settings: 'salt' on;

        /* Neutral/900 */

        color: #111827;


        /* Inside auto layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 2px;
    }

    .amount-due-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        width: 100%;
        background: #FFFFFF;

        box-shadow: 0px 4px 12px rgba(96, 104, 118, 0.5);
        margin: 10px 0px;
    }


    .top-section {
        margin-top: 110px;
    }


    nav {
        height: 40px;
    }
}

label, input {
    padding-left: 0 !important;
}

input {
    padding-top: 10px;
    padding-bottom: 10px;
}

.billing-information-block .row > * {
    padding-left: 0 !important;
}

.billing-information-block .row {
    margin-right: 0;
    margin-left: 0;
}

/* added */
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.flex-item {
    flex-grow: 1;
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
}

.flex-item:last-child {
    margin-right: 0;
}

.flex-item:first-child {
    margin-left: 0;
}

input::placeholder {
    font-weight: 400;
    font-size: 14px;
}

.form-check-input {
    width: 16px;
    height: 16px;
    padding: 0;
}


@media only screen and (max-width: 600px) {
    .container {
        padding: 0;
    }

    .wrap-mobile {
        flex-wrap: wrap;
    }

    .wrap-mobile .flex-item {
        margin: 0;
        width: 100% !important;
    }
}



.popup-content {
    position: relative;
    width: 300px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
    border: none;
    overflow: hidden;
    padding: 40px;
    padding-bottom: 80px;
}

.forty-percent-width {
    width: 40%;
}

.ach-button:disabled {
    background-color: dimgrey;
    color: linen;
    opacity: 1;
}

.ach-popup-content {
    width: 50% !important;
    padding-bottom: 40px !important;
}

.m-t-20 {
    margin-top: 20px;
}

.close-button {
    font-size: 3em;
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.topright {
    position: absolute;
    right: 0;
    top: -10px;
}


.error-message {
    font-size: 16px;
}

.title-popup {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.close-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: white;
    background: #ff4d4e;
    border: none;
    font-weight: 500;
}

.popup-overlay {
    position: fixed;
    inset: 0px;
    display: flex;
    z-index: 999;
    pointer-events: auto;
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
}

.spinner .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.success-icon g {
    fill: rgb(37, 174, 136);
}

.success-icon polyline {
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.success-title {
    color: rgb(37, 174, 136);
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.m-t-40 {
    margin-top: 40px;
}


.m-b-100 {
    margin-bottom: 100px;
}

.lock {
    width: 20px;
    height: 20px;
}

.lock path {
    fill: var(--primary-color) !important;
}

a:hover {
    color: var(--primary-color) !important;
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners {
    -moz-appearance:textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.paymentMethodIcon {
    height: 40px;
}

.hCenter {
    text-align: center;
}


.apple-pay-button {
//--apple-pay-button-width: 140px;
    --apple-pay-button-width: 250px;
    --apple-pay-button-height: 50px;
    --apple-pay-button-border-radius: 5px;
    --apple-pay-button-padding: 5px 0px;
}


.center-payment-method {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-gutter-3rem {
    --bs-gutter-x: 3rem;
}
