a.footer-link {
    cursor: pointer;
    display: inline;
    align-items: center;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid #000000;
    border-radius: 0 0 0px 0px;
}

a.footer-link:active {
    border-bottom: 1px solid #006BB3;
}

.footer-container {
    overflow: hidden;
    padding-top: 100px;
}

.footer {
    position: relative;
}

.ellipse {
    position: absolute;
    width: 100vw;
    height: 100vw;
    top: 0px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px -30px 90px rgb(96 104 118 / 20%);
    border-radius: 50%;
    z-index: -1;
}
